/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import { graphql, Link, withPrefix } from 'gatsby';
// import { Waypoint } from 'react-waypoint';
/** @jsx jsx */
import { Heading, jsx } from 'theme-ui';
import { Global, keyframes } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { useMove } from '../components/heroText';
import { GraphQLErrorList, SEO, PortableText, Rsvp, HeroText } from '../components';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    homepage: sanityIndexPage {
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const IndexPage = ({ data, graphQLErrors }) => {
  if (graphQLErrors) {
    return <GraphQLErrorList graphQLErrors={graphQLErrors} />;
  }

  const { site, homepage } = data || {};

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { x, y, handleMouseMove } = useMove();

  return (
    <>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      {/* <Waypoint topOffset="20%" onEnter={() => changeColor('yellow')}> */}
      <section onMouseMove={handleMouseMove} sx={{ variant: 'section.hero', height: '100vh' }}>
        {homepage._rawBody && <PortableText blocks={homepage._rawBody || []} />}
        <div
          sx={{
            variant: 'section.hero.text',
          }}
        >
          <HeroText
            text="&nbsp;&&nbsp;"
            fontSize={500}
            fontFamily="heading"
            rotation={x * 0.1}
            rgbOffset={y * 0.0001}
            addBlur
            addNoise={0.25}
          />
        </div>
      </section>
      {/* </Waypoint> */}

      {/* <Waypoint topOffset="20%" onEnter={() => changeColor('red')}> */}
      <section id="rsvp">
        <Rsvp />
      </section>
      {/* </Waypoint> */}

      {/* <Waypoint topOffset="20%" onEnter={() => changeColor('blue')}> */}
      {/* <section sx={{ height: '100vh' }}>
        <h1>Section 3</h1>
      </section> */}
      {/* </Waypoint> */}

      {/* <div sx={{ variant: 'fixedFooter' }}>
        <div sx={{ variant: 'fixedFooter.left' }}>{site.footerInfo.weddingDate}</div>
        <div sx={{ variant: 'fixedFooter.right' }}>{site.footerInfo.location}</div>
      </div> */}
    </>
  );
};

export default IndexPage;
